.App {
  display: flex;
  flex-direction: row;
  max-width: 1800px;
  margin: auto;
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 300px;
  background: #eff7ff;
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #85c1ff;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}
.side-bar-header h3 {
  margin: 0;
}
.side-bar-body {
  padding: 20px;
  text-align: left;
}

.side-bar-body ol,
.side-bar-body ul{
  padding: 0 20px;
}

.side-bar-body li{
  margin-bottom: 10px;
}
.side-bar-body li>a{
  text-decoration: none;
  color: #000;
}

.side-bar-body li>a:hover,
.side-bar-body li>a:active{
  cursor: pointer;
  color: #85c1ff;
}

.side-bar-body li.active > a {
  border-bottom: 2px solid #85c1ff;
}

.swagger-ui {
  width: 90vw;
  padding-left: 30px;
  padding-top: 0px;
}

.swagger-ui .info {
  margin: 30px 0;
}

.swagger-ui .info .title {
  margin-bottom: 25px;
}